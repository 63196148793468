import $ from 'jquery';

const SysComments = {};

(function (self) {
    self.mass_load_url = '/comments/comments_mass_load/';
    self.SUCCES_CODE = 'OK';
    self.container = null;

    self.mass_load = function (data, url) {
        $.ajax({
            type: 'POST',
            url,
            contentType: 'application/json; charset=utf-8',
            dataType: 'json',
            data: JSON.stringify({ objects: data }),
            success(response) {
                if (response.result === self.SUCCES_CODE) {
                    if (!response.comments) {
                        return;
                    }

                    $.map(response.comments, (item) => {
                        const box = $(self.container).find(
                            `${'.comment_count' +
                           '[data-id='}${item.object_id}]` +
                           `[data-ct=${item.content_type_id}]`,
                        );
                        if (item.count) {
                            box.css('display', 'inline');
                            box.find('.comment_cnt').text(item.count);
                            if (item.new) {
                                box.find('.new_comment_cnt').text(item.new);
                            }
                        }
                        box.attr('load_done', 1);
                    });

                    $.map($(self.container).find('.comment_cnt'), (item) => {
                        item = $(item);
                        if (!item.clone().children().remove().end()
                            .text()
                            .trim().length) {
                            item.text(0);
                        }
                    });
                }
            },
        });
    };

    self.init = function (container) {
        if (container == undefined) {
            container = $(document);
        }
        self.container = container;

        if (self.container.find('.comment_count').length === 0) {
            return;
        }

        const comments = [];
        $.map(self.container.find('.comment_count'), (item) => {
            if ($(item).attr('load_done') == undefined) {
                comments.push({
                    object_id: $(item).data('id'),
                    content_type_id: $(item).data('ct'),
                });
                $(item).attr('load_done', 1);
            }
        });

        self.mass_load(comments, self.mass_load_url);
    };
}(SysComments));

window.SysComments = SysComments;

export default SysComments;
