import $ from 'jquery';

const SysInitViewsCount = function () {
    const objectList = [];
    let url;

    const viewsList = $('.views_count');

    viewsList.each(function () {
        objectList.push([$(this).attr('data-content_type_id'), $(this).attr('data-object_id')]);
        if (!url) {
            url = $(this).attr('data-url');
        }
    });

    if (url) {
        $.get(url, { data_list: JSON.stringify(objectList) }, (data) => {
            let mapKey;
            let totalBlock;
            let href;
            let objID;
            let objIDSelect;

            viewsList.each(function () {
                mapKey = [$(this).attr('data-content_type_id'), $(this).attr('data-object_id')].join(':');

                totalBlock = '';
                if (data.views_count && data.views_count[mapKey]) {
                    totalBlock = ` / <span title="Всего просмотров">${data.views_count[mapKey]}</span>`;
                }

                if (data.views_count_unique[mapKey]) {
                    objID = `user-views${$(this).data('object_id')}`;
                    $(this).append(`${'<i class="fa fa-eye view-icon" aria-hidden="true"></i>&nbsp;<span title="Уникальных просмотров" id="'}${objID}">${data.views_count_unique[mapKey]}</span>${totalBlock}`);
                    if ($(this).data('views-display') !== 'False' && ($(this).data('object-ctype')) === 'entry') {
                        href = $(this).find('input').val();
                        objIDSelect = `#${objID}`;
                        $(objIDSelect).wrap(`<a class='unique-user-views' title='Показать прочитавших' href=${href}></a>`);
                    }
                }
            });
        });
    }
};

$(document).ready(() => {
    SysInitViewsCount();
});
