import $ from 'jquery';

const SysLikes = {};

(function (self) {
    // Init likes when ajax
    self.init = function (container) {
        const c = container || $(document);
        const newLikeContainers = c.find('.like_container:not(.load_done)').addClass('load_done');
        if (newLikeContainers.length) {
            $('.timeline-block').initLikes();
        }
    };
}(SysLikes));

window.SysLikes = SysLikes;

export default SysLikes;
